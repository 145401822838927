import React from "react";
import styles from "./LoginPageMobile.module.scss";
import { withTopBarContext } from "../../utils/TopBarContext";
import withLoginPage from "../../controllers/withLoginPage/withLoginPage";
import { compose } from "../../utils/common";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import commonStyles from "../../styles/common.module.scss";
import { ReactComponent as UserIcon } from "../../images/UserIcon.svg";
import TextInput from "../../components/UI/TextInput/TextInput";
import PasswordInput from "../../components/UI/PasswordInput/PasswordInput";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import LoginBackgroundMobile from "../../fragments/LoginBackgroundMobile/LoginBackgroundMobile";
import Grid from "@material-ui/core/Grid";
import AlertDismissable from "../../components/AlertDismissable/AlertDismissable";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { Alert } from "react-bootstrap";

const bgLoginTransparent = require("../../images/bg-login-no-fill-ext.png");
const bgForgotPwdTransparent = require("../../images/bg-forgot-pwd-no-fill.png");
const bgUrlSentTransparent = require("../../images/bg-url-sent-no-fill.png");

const LoginPageMobile = compose(
  withTopBarContext({ isFullscreen: true }),
  withLoginPage
)(({ pageActions, renderFieldValidator, user, ...props }) => {
  // if (/login-error/.test(user.status)) {
  //   alert(`${user.error.message}. Please try again`);
  // }
  if (pageActions.state.forgotConsent) {
    return (
      <LoginBackgroundMobile hasBgImg={bgUrlSentTransparent}>
        <h2 className="text-primary">
          Check inbox!
        </h2>
        <Form>
          <Form.Group>
            <div className={"text-muted"}>
              The password recovery link was sent to your email
            </div>
          </Form.Group>
          <Form.Group>
            <Grid container spacing={2} wrap="wrap-reverse">
              <Grid item xs={12} sm={12} fullWidth>
                <a href="/login" className={""}>
                  <Button
                    className={`w-100 ${styles.textEllipsis}`}
                    style={{}}
                    variant={"light"}
                    type={"button"}
                  >
                    Back to login
                  </Button>
                </a>
              </Grid>
            </Grid>
          </Form.Group>
        </Form>
      </LoginBackgroundMobile>
    );
  }
  else {
    return (
      <LoginBackgroundMobile hasBgImg={!pageActions.isForgotPassword ? bgLoginTransparent : bgForgotPwdTransparent}>
        <div>
          <h2 className="text-primary">
            {pageActions.isForgotPassword ? "Forgot password?" : "Login"}
          </h2>
          <Form>
            {(!pageActions.isForgotPassword && user.status === "login-error") && (
              <Form.Group>
                <AlertDismissable variant={"danger"}>
                  {/* {user.error?.message} */}
                  {'Invalid Emaill Address / Password.'}
                </AlertDismissable>
              </Form.Group>
            )}
            {pageActions.isForgotPassword && (
              <>
                <Form.Group>
                  <div style={{ width: "80%" }} className={"text-muted"}>
                    Enter your e-mail and we will send you a link to reset your
                    password
                  </div>
                </Form.Group>
                {pageActions.state.forgotPwdErrMessage && (
                  <Form.Group>
                    <AlertDismissable variant={"danger"}>
                      {/* {pageActions.state.forgotPwdErrMessage} */}
                      {'Invalid Emaill Address.'}
                    </AlertDismissable>
                  </Form.Group>
                )}
              </>
            )}
            <Form.Group>      
              <TextInput
                id={"email"}
                label="Email"
                placeholder={(
                  !pageActions.isForgotPassword ? "Email Address" : "Enter your email address"
                )}
                type="email"
                size={"lg"}
                variant={"grey"}
                {...props.formBinding("email")}
              >
              </TextInput>
              {[
                pageActions.validator.message(
                  "Email",
                  pageActions.form.email,
                  "required|email"
                )
              ]}
            </Form.Group>

            {/* if normal login */}
            {!pageActions.isForgotPassword && (
              <PasswordInput
                id={"password"}
                placeholder={"Password"}
                size={"lg"}
                {...props.formBinding("password")}
                variant={"grey"}
                style={{ marginBottom: "0.7rem" }}
              >
                {[
                  pageActions.validator.message(
                    "Password",
                    pageActions.form.password,
                    "required"
                  )
                ]}
              </PasswordInput>
            )}
            {/* If forgot password, just show submit button */}
            {!pageActions.isForgotPassword ? (
              <>
                <div className={"mb-4 mt-2 text-right"}>
                  <Link to={"/login/forgot"} className={"no-link text-muted"}>
                    Forgot password?
                  </Link>
                </div>
                <Form.Group>
                  <Button
                    id={"submit"}
                    variant={"primary"}
                    className={`w-100 ${styles.Uppercase}`}
                    style={{ paddingLeft: "3rem", paddingRight: "3rem", marginBottom: "2rem", borderRadius: 7 }}
                    disabled={pageActions.state.isLoggingIn}
                    type={"button"}
                    onClick={() => pageActions.doLoginMobile()}
                  >
                    Login
                  </Button>
                </Form.Group>
                <div className={styles.Register}>
                  <span className={"text-muted"}>No account yet? </span>
                  <Link to={"/register"} className={"no-link font-weight-bold"}>
                    Register here
                  </Link>
                </div>
                <Alert
                variant="warning"
                >
                  <h6>🚀 Try New Version!</h6>
                  <span>Exciting News! The new version of our platform is here! Experience enhanced features, improved performance, and a sleek new design.</span>
                  <a className="d-block" href="https://v2.qooee.com">Try now!</a>
                </Alert>
              </>
            ) : (
              <Form.Group>
                <Grid container spacing={2} wrap="wrap">
                <Grid item xs={12} sm={6} fullWidth>
                    <Button
                      className={`w-100 ${styles.Uppercase} ${styles.textEllipsis}`}
                      variant={"primary"}
                      onClick={() => pageActions.doForgotMobile()}
                      style={{ borderRadius: 7 }}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} fullWidth>
                    <Button
                      className={`w-100 ${styles.textEllipsis}`}
                      variant={"light"}
                      onClick={() => props.history.goBack()}
                      style={{ borderRadius: 7 }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  
                </Grid>
              </Form.Group>
            )}
          </Form>
        </div>
      </LoginBackgroundMobile>
    );
  }
  
});

export default LoginPageMobile;
